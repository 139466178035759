import Vue from 'vue'
import VueRouter from 'vue-router'

const Main = () => import('../components/Main.vue');

Vue.use(VueRouter);

const routes = [
    {
        name: 'Main',
        path: '/',
        component: Main,
    },
    {
        path: '*', redirect: '/'
    }
];

export const router = new VueRouter({
  routes,
  mode: 'history',
});

