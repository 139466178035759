<template>
    <div class="d-flex flex-column align-items-center pt-24">
        <div>
            <div v-if="historyDeal.length" class="hystory-wrap">
                <div class="m-6 mb-12">
                    <span>Previous Deals: </span>
                </div>
                <div class="m-6 mb-12 d-flex justify-content-between">
                    <span>Value</span>
                    <span>Bid</span>
                </div>
                <div v-for="(item, k) in historyDeal"
                     :key="k"
                     class="m-6 mb-12 d-flex justify-content-between">
                    <span>{{ item.value }}</span>
                    <span>
                        <span v-if="item.upDown">⬆</span>
                        <span v-else>🔻</span>
                        <span :class="colorHistory(item)">{{ item.bid }}</span>
                    </span>
                </div>
                <hr />

            </div>
            <template v-if="Object.keys(bidInfo).length">
                <hr />
                <!-- Для демо отключаю
                 <div class="m-6 mb-12">
                     <span>Bid: </span>
                     <span>{{ bidInfo.bid }}$</span>
                 </div>
                 -->
                <div class="m-6 mb-12">
                    <span>Trade: </span>
                    <span :class="bidInfo.upDown ? 'color-green' : 'color-red'">{{ upDown }}</span>
                </div>
                <div class="m-6 mb-12">
                    <span>Start Price: </span>
                    <span>{{ valStartContract }}</span>
                </div>
                <div class="m-6 mb-12">
                    <span>Current Price: </span>
                    <span :class="{'color-green': win, 'color-red': !win }">{{ graphNow }}</span>
                </div>

            </template>
        </div>
        <div class="d-flex align-items-center justify-content-center flex-column w-100">
            <div class="d-flex align-items-center justify-content-center w-100">
                <el-button class="btn-inc"
                           :disabled="disabled"
                           @click="clickMinus('bid')">-</el-button>
                <el-input class="input-number"
                          type="number"
                          :disabled="disabled"
                          @input="inputNumber('bid', $event)"
                          v-model="bid"
                          ref="bid"
                          :min="1">
                </el-input>
                <p class="m-0 input-number--dollar">$</p>
                <el-button class="btn-inc"
                           @input="inputNumber('bid', $event)"
                           :disabled="disabled"
                           @click="clickPlus('bid')">+</el-button>
                <a href = "javascript:void(0);"
                   :class="{'pointer-none': disabled}"
                   @click="maxCount('bid', 'contractBallance')" class="max-count">max</a>
            </div>
            <p v-if="bid" class="text-center">{{ cource(bid) }}</p>

            <div clas="d-flex flex-row align-items-center">
                <el-button type="success"
                           :disabled="disabled"
                           class="btn-up"
                           @click="callContract(true)"
                >Up
                </el-button>
                <el-button class="btn-down"
                           :disabled="disabled"
                           @click="callContract(false)"
                           type="danger"
                >Down
                </el-button>
            </div>
            <div v-if="historyHashTransfer && historyHashTransfer.length" class="mt-12 w-100">
                <p class="color-white text-bold ml-6">History transfers:</p>
                <a v-for="item in historyHashTransfer"
                   target="_blank"
                   class="text-ellipsis color-gray"
                   :href="`https://goerli.etherscan.io/tx/${item}`">
                    {{item}}
                </a>
            </div>
            <div v-if="historyHashDeal && historyHashDeal.length" class="mt-12 w-100">
                <p class="color-white text-bold ml-6">History deals:</p>
                <a v-for="item in historyHashDeal"
                   target="_blank"
                   class="text-ellipsis color-gray"
                   :href="`https://goerli.etherscan.io/tx/${item}`">
                    {{item}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import { Mixin } from './mixin';

let web3 = new Web3(window.ethereum);

// let contract = new web3.eth.Contract(abi, contractAddress)

export default {
    name: 'SideBar',
    mixins: [Mixin],
    data() {
        return {
            bid: 1,
            startingContract: false,
            bidInfo: {},
            //contractAddress: '0xf44650B1db3F864eA7CCd8801041007420866aC6',
            //userWalletAddress: '',
        };
    },
    computed: {
        ...mapState([
            'timeCounter',
            'ballance',
            'contractBallance',
            'graphLength',
            'graphNow',
            'valStartContract',
            'historyDeal',
            'historyHashDeal',
            'historyHashTransfer',
            'userWalletAddress',
            'contractAddress',
            'contractData',
            'userWalletAddress',
            'ethExchRate',
        ]),
        disabled() {
            return this.timeCounter !== null && this.timeCounter !== 0 || !this.graphLength || this.startingContract;
        },
        upDown() {
            return this.bidInfo.upDown ? 'up' : 'down';
        },
        win() {
            return ((this.bidInfo.upDown && (this.valStartContract < this.graphNow))
                || (!this.bidInfo.upDown && (this.valStartContract > this.graphNow)));
        },
    },
    watch: {
        timeCounter(val) {
            console.log('timeCounter', val, Object.keys(this.bidInfo));
            if (!val && Object.keys(this.bidInfo).length) {
                let finishObj = {
                    upDown: this.bidInfo.upDown,
                    value: this.valStartContract,
                    bid: this.graphNow,
                };

                let win = ((finishObj.upDown && (finishObj.value < finishObj.bid))
                    || (!finishObj.upDown && (finishObj.value > finishObj.bid)));
                this.bidInfo = {};
                this.$notify({
                    title: 'Deal closed',
                    message: win ? 'Your profit $5' : 'Your loss 5$',
                    type: win ? 'success' : 'success',
                    duration: 5000,
                });
                console.log('finishObj', finishObj);
                this.$store.dispatch('setHistoryDeal', {val: finishObj});
                this.bidInfo = {};
            }
        },
    },
    methods: {
        /** method for calling the contract method */
        async callContract(isUp) {

            //Возможно нужно переделать контракт и отправлять сумму в $$$ чтобы сократить стоимость газа
            let bidDollars = Math.round((this.bid * 1000000000000000000) / this.ethExchRate);
            //let bidDollars = Math.round(this.bid);
            console.log('bidDollars', bidDollars);
            let contract = new web3.eth.Contract(this.contractData, this.contractAddress,
                { from: this.userWalletAddress });
            contract.methods.placeBid(bidDollars, 1, isUp).send({ from: this.userWalletAddress })
                .on('transactionHash', (hash) => {
                    console.log('hash', hash);
                    this.$store.dispatch('setHistoryHashDeal', {val: hash});

                    this.startingContract = true;
                    this.$store.commit('setSending', true);
                })
                .then((res) => {
                contract.methods.getMyTrades().call({ from: this.userWalletAddress }, (err, res) => {
                    console.log('getMyTrades', res);
                    this.bidInfo.bid = res?.bidAmount;//Math.round((res[0] * 1849) * 100) / 100;
                    let timeToEnd = (res.whenTradeEnds * 1000 - Date.now())/1000;

                    this.bidInfo.upDown = isUp;
                    //PLACE BID HERE (!!!!!!!!)
                    this.$store.commit('setBid', { fromButton: true, up: isUp, val: this.bid, bidInfo: res });
                    //старт таймера обратного отсчёта
                    this.$store.dispatch('setTimeCounter', timeToEnd);
                    this.startingContract = false;
                });
                //ЗДЕСЬ НУЖНО ОБНОВИТЬ БАЛАНС ИЗ МЕТОДА В ХЭДЕРЕ
                //this.$root.$refs.Header.getBallance();

                this.$store.commit('setSending', false);

            }).catch((err) => {
                this.$store.commit('setSending', false);
                this.startingContract = false;
                this.$notify({
                    title: 'Warning',
                    message: err.message,
                    type: 'warning',
                    duration: 10000,
                });
            });
        },

        colorHistory(item) {
            if ((item.upDown && (item.bid > item.value)) || (!item.upDown && (item.bid < item.value))) {
                return 'color-green';
            } else {
                return 'color-red';
            }
        },
    },
};
</script>

<style scoped>

</style>
