<template>

    <div class="d-flex flex-row align-items-center">

        <div class="d-flex p-12">
            <img :src="'/fairoption.png'" height="41px" width="180px"
                 style="border:0.01em solid #fff602; border-radius: 0%; padding:1px; margin:1px" />
        </div>

        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex flex-grow-1 p-12 justify-content-end align-items-center">
                <div class="p-12" title="Deposit funds to your trader balance">
                    <el-button size="mini" :disabled="disableBtnDeposit" @click="depositModal = true">Deposit</el-button>
                </div>
                <div class="p-12" title="Trader Balance">
                    <span>📈 ${{ contractBallance }}</span>
                </div>
                <div class="p-12" title="Your Wallet Balance">
                    <span>💰 ${{ ballance }}</span>
                </div>
            </div>
            <div class="d-flex align-items-center" v-if="timeCounter !== 0 && timeCounter !== null">
                <span>Time to end: </span>
                <span>00:00:{{ timeCounter ? timeCounter : '00' }}</span>
            </div>
        </div>
<!--        модалка пополнения баланса-->
        <el-dialog
            title="Deposit your trader account"
            :center="true"
            class="deposit-modal"
            :show-close="false"
            :visible.sync="depositModal"
            width="400px">
            <div class="d-flex align-items-center justify-content-center">
                <el-button class="btn-inc" @click="clickMinus('sendMoney')">-</el-button>
                <el-input class="input-number"
                          type="number"
                          @input="inputNumber('sendMoney', $event)"
                          v-model="sendMoney"
                          ref="sendMoney"
                          :min="1">
                </el-input>
                <p class="input-number--dollar">$</p>
                <el-button class="btn-inc"
                           @input="inputNumber('sendMoney', $event)"
                           @click="clickPlus('sendMoney')">+</el-button>
                <a href = "javascript:void(0);" @click="maxCount('sendMoney', 'ballance')" class="max-count">max</a>
            </div>
            <p v-if="sendMoney" class="text-center">{{ cource(sendMoney) }}</p>
            <div slot="footer" class="dialog-footer d-flex justify-content-between">
                <el-button type="success"
                           :disabled="!sendMoney"
                           @click="sendTransaction">
                    Confirm
                </el-button>
                <el-button @click="depositModal = false"
                           type="danger">
                    Cancel
                </el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import { Mixin } from './mixin'

let web3 = new Web3(window.ethereum);

export default {
    name: 'Header',
    mixins: [Mixin],

    data() {
        return {
            depositModal: false,
            sendMoney: 10, //сумма для перевода
        };
    },
    computed: {
        ...mapState([
            'ballance',
            'contractBallance',
            'userWalletAddress',
            'contractAddress',
            'contractData',
            'ethExchRate',
            'timeCounter'
        ]),
        disableBtnDeposit() {
            return !this.contractAddress || !this.userWalletAddress;
        },
    },
    methods: {
        async getWalletAddress() {
            console.log('getWalletAddress');
            await web3.eth.requestAccounts().then((res) => {
                this.$store.commit('setUserWalletAddress', res[0]);
                this.getBallance(); //get user and contract ballance
            }).catch((err) => {
                this.$notify({
                    title: 'Info',
                    message: err.message,
                    type: 'info',
                    duration: 10000,
                });
                this.startingContract = false;
            });
        },
        getBallance() {
            let contract = new web3.eth.Contract(this.contractData, this.contractAddress,
                { from: this.userWalletAddress });
            //ПОЛУЧЕНИЕ БАЛАНСА МОЕГО КОШЕЛЬКА
            web3.eth.getBalance(this.userWalletAddress).then((res) => {
                res = Web3.utils.fromWei(res, 'ether');
                //!!!! НУЖНО ЗАМЕНИТЬ ethExchRate в сторе НА ТЕКУЩУЮ ЦЕНУ ЭФИРА
                res = Math.round((res * this.ethExchRate) * 100) / 100;
                this.$store.commit('setBallance', res);
            });

            //ПОЛУЧЕНИЕ БАЛАНСА МОЕГО БАЛАНСА МОЕГО КОНТРАКАТА
            contract.methods.getBalance().call({ from: this.userWalletAddress }, (err, res) => {
                res = Web3.utils.fromWei(res, 'ether');
                //!!!! НУЖНО ЗАМЕНИТЬ ethExchRate в сторе НА ТЕКУЩУЮ ЦЕНУ ЭФИРА
                res = Math.round((res * this.ethExchRate) * 100) / 100;
                this.$store.commit('setContractBallance', res);
            });
        },
        sendTransaction() {
            console.log('sendTransaction start: ');
            this.depositModal = false;
            web3.eth.sendTransaction({
                to: this.contractAddress,
                from: this.userWalletAddress,
                value: Math.round((this.sendMoney * 100000000000000000) / this.ethExchRate )
            }).on('transactionHash', (hash) => {
                this.$store.dispatch('setHistoryHashTransfer', {val: hash});
            }).then((res) => {
                console.log('sendTransaction res: ', res);
                this.getBallance();
            }).catch((err) => {
                console.log('sendTransaction err: ', err);
            })

        },
    },
    mounted() {
        this.getWalletAddress();
    },
};
</script>

<style scoped>

</style>
