import Vue from 'vue';
import Vuex from 'vuex';
import contractData from '../data/contract.json';
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    bid: {},
    contractData: contractData, //данные контракта
    etherUsdPrice: null,
    timeCounter: null,
    ballance: null,
    contractBallance: null,
    graphLength: null, //есть ли в графике элементы,
    loading: false,
    loadingText: '',
    contractAddress: '0xf88DC0cb125F07892C323E7567BbA7543bE3765a', // адрес контракта
    userWalletAddress: null, // адрес кошелька пользователя
    graphNow: null, //текщее значение из графика
    valStartContract: null, //значение графика на момент начала сделки,
    historyDeal: [],
    sending: false,//отправка данных в блокчейн для начала сделки
    ethExchRate: 1570, //курс эфира, !!!ПОЗЖЕ СДЕЛАТЬ ДИНАМИЧКСКИМ
    historyHashDeal: [], //массив хэшей сделок
    historyHashTransfer: [], //массив хэшей переводов
  },
  mutations: {
    setBid(state, payload) {
      console.log('111');
      state.bid = payload;
    },
    setEtherPrice(state, payload){

      state.etherUsdPrice = payload;

    },
    setUserWalletAddress(state, payload){

      state.userWalletAddress = payload;

    },
    setGraphNow(state, payload) {
      state.graphNow = payload;
    },
    setValStartContract(state, payload) {
      state.valStartContract = payload;
    },
    setBallance(state, payload) {
      state.ballance = payload;
    },
    setContractBallance(state, payload) {
      state.contractBallance = payload;
    },
    setGraphLength(state, payload) {
      state.graphLength = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
      if (!payload) {
        state.loadingText = '';
      }
    },
    setSending(state, payload) {
      state.sending = payload;
    },
    setLoadingText(state, payload) {
      state.loadingText = payload;
    }
  },
  actions: {
    setTimeCounter({ commit, state }, payload) {
      let timeToEnd = payload ? Math.round(payload) : 59;
      console.log('timeToEnd store', timeToEnd);
      state.timeCounter = timeToEnd;
      if (timeToEnd > 0) {
        let startCount = setInterval(() => {
          --state.timeCounter;
          if (state.timeCounter === 0) {
            clearInterval(startCount);
          }
        },1000)
      }
    },
    /** Преобразование значений инпута в числовые
     * @param {object} payload.val массив хэшей операций
     * @param {boolean} payload.fromLs заполнение массива хэшей из localStorage
     * */
    setHistoryDeal({ commit, state }, payload) {
      if (!payload.fromLs) {
        state.historyDeal.push(payload.val);
        localStorage.setItem('historyDeal', JSON.stringify(state.historyDeal));
      } else {
        state.historyDeal.push(...payload.val);
      }
    },
    setHistoryHashDeal({ commit, state }, payload) {
      if (!payload.fromLs) {
        state.historyHashDeal.push(payload.val);
        localStorage.setItem('historyHashDeal', JSON.stringify(state.historyHashDeal));
      } else {
        state.historyHashDeal.push(...payload.val);
      }
    },
    setHistoryHashTransfer({ commit, state }, payload) {
      if (!payload.fromLs) {
        state.historyHashTransfer.push(payload.val);
        localStorage.setItem('historyHashTransfer', JSON.stringify(state.historyHashTransfer));
      } else {
        state.historyHashTransfer.push(...payload.val);
      }
    }
  },
});

export default store;
