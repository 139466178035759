import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import { router } from '../src/routes/router'
import store from '../src/store/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { Button, Input, Container, Header, Main, Aside, InputNumber } from 'element-ui';
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Container.name, Container);
Vue.component(Header.name, Header);
Vue.component(Main.name, Main);
Vue.component(Aside.name, Aside);

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
