export const Mixin = {
    methods: {
        /** Преобразование значений инпута в числовые
         * @param {string} field Имя переменной и реф элемента
         * @param {string} value значение поля
         * */
        inputNumber(field, value) {
            this.$refs[field].$refs.input.style.width = (value.toString().length ) + 'ch'
        },

        /** Пересчет долларов в эфириумы */
        cource(dollar) {
            return `${dollar}$ ~ ${Math.round((dollar * 100000) / this.ethExchRate) / 100000} Eth`
        },

        /** Максимальный балланс для сделки
         * @param {string} field Имя переменной и реф элемента
         * @param {string} ballance contractBallance - баланс контракта, ballance - баланс кошелька
         * */
        maxCount(field, ballance) {
            this[field] = Math.floor(this[ballance]);
            this.inputNumber(field, this[field]);
        },

        /** Инкремент суммы сделки
         * @param {string} field Имя переменной и реф элемента
         * @param {string} val Имя переменной и реф элемента
         * */
        clickPlus(field) {
            this[field]++;
            this.inputNumber(field, this[field]);
        },

        /** Декремент суммы сделки
         * @param {string} field Имя переменной и реф элемента
         * */
        clickMinus(field) {
            this[field]--;
            this.inputNumber(field, this[field]);
        }
    }
}
