<template>
  <div id="app">
      <el-container>
          <nav-menu/>
          <el-container class="main-content">
              <el-header class="background-back p-12" height="76px">
                  <header-view></header-view>
              </el-header>
              <el-main class="p-0 background-back ">
                  <router-view :key="$route.path"></router-view>
              </el-main>
          </el-container>
          <el-aside class="border background-back p-12">
              <side-bar></side-bar>
          </el-aside>
      </el-container>
  </div>
</template>

<script>

import SideBar from './components/SideBar.vue';
import HeaderView from './components/HeaderView.vue';
import NavMenu from './components/NavMenu.vue';

export default {
  name: 'App',
  components: {
      NavMenu,
      SideBar,
      HeaderView
  }
}
</script>

<style lang="scss">
@import 'styles/style.scss';
</style>
