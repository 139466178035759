<template>
    <div class="nav-menu__wrap"
         :class="{'nav-menu__wrap--opened': !isCollapse}"
         @mouseover="mouseOver"
         @mouseleave="mouseLeave">
        <el-menu default-active="2"
                 class="el-menu-vertical-demo color-gray"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 :collapse="isCollapse">
            <el-radio-group class="nav-menu__buttons" v-model="isCollapse" style="margin-bottom: 20px;">
                <el-radio-button v-show="isCollapse" :label="false"><img src="@/assets/icons/menu.svg" svg-inline /></el-radio-button>
                <el-radio-button v-show="!isCollapse" :label="true"><img src="@/assets/icons/menu3.svg" svg-inline /></el-radio-button>
            </el-radio-group>
            <el-submenu index="1">

                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span slot="title">Navigator One</span>
                </template>
                <el-menu-item-group>
                    <span slot="title">Group One</span>
                    <el-menu-item index="1-1">item one</el-menu-item>
                    <el-menu-item index="1-2">item two</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group title="Group Two">
                    <el-menu-item index="1-3">item three</el-menu-item>
                </el-menu-item-group>
                <el-submenu index="1-4">
                    <span slot="title">item four</span>
                    <el-menu-item index="1-4-1">item one</el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-menu-item index="2">
                <i class="el-icon-menu"></i>
                <span slot="title">Navigator Two</span>
            </el-menu-item>
            <el-menu-item index="3" disabled>
                <i class="el-icon-document"></i>
                <span slot="title">Navigator Three</span>
            </el-menu-item>
            <el-menu-item index="4">
                <i class="el-icon-setting"></i>
                <span slot="title">Navigator Four</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'NavMenu',
    data() {
        return {
            isCollapse: true
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        mouseOver() {
            this.$store.commit('common/setCollapsedShow', true);
        },
        mouseLeave() {
            if (this.collapsed) {
                this.isObjectExpanded = false;
            }
            this.$store.commit('common/setCollapsedShow', false);
        },
    }
};
</script>

<style scoped>

</style>
